import React from 'react';
import { getUrlParams } from '../../helpers/common';
import { formatTimestamp } from '../../helpers/date-and-time';
import { useSelector } from 'react-redux';
import moment from 'moment';

const MessageBubble = ({ message, index }) => {
  const { history, selectedChat } = useSelector((s) => s.message);
  const urlParams = getUrlParams();

  const senderId = urlParams?.sender;

  const isCurrentUserSender = senderId === message?.userId;

  // Date Tag Logic
  let showDateTag = false;
  let prevMessage = history?.[selectedChat?.channel?.id]?.messages?.[index - 1];
  if (prevMessage) {
    const prevMessageDate = moment
      .unix(prevMessage?.timetoken / 10000000)
      .format('DD-MM-YYYY');
    const currentDate = moment(
      moment.unix(message?.timetoken / 10000000),
    ).format('DD-MM-YYYY');
    if (prevMessageDate !== currentDate) {
      showDateTag = true;
    } else {
      showDateTag = false;
    }
  } else {
    showDateTag = true;
  }

  const renderFileContent = (file) => {
    if (!file) return null;

    switch (file?.type) {
      case 'image/png':
      case 'image/jpeg':
        return (
          <a href={file?.url} target="_blank">
            <img
              className="rounded-md"
              src={file?.url}
              height={'300px'}
              width={'300px'}
            />
          </a>
        );
      // case 'application/pdf':
      default:
        return (
          <a href={file?.url} target="_blank">
            <div className="h-[100px] w-auto flex flex-col items-center justify-center border-dashed border-2 border-gray-200 rounded-md p-4">
              <i className="fa-regular fa-file text-2xl text-gray-200" />
              <div className="text-[12px]">{file?.name}</div>
            </div>
          </a>
        );
    }
  };

  const renderMessage = () => {
    const urlPattern =
      /\b((?:https?:\/\/|www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&//=]*))/gi;
    const parts = message?.content?.text?.split(urlPattern);

    return parts?.map((part, index) => {
      if (urlPattern.test(part)) {
        const href = part?.startsWith('http') ? part : `http://${part}`;
        return (
          <a
            key={index}
            className="text-blue-500"
            href={href}
            target={'_blank'}
            style={{ textDecoration: 'underline' }}
          >
            {part}
          </a>
        );
      } else {
        return (
          <span style={{ whiteSpace: 'pre-line' }} key={index}>
            {part}
          </span>
        );
      }
    });
  };

  return (
    <>
      {showDateTag && (
        <div className="w-full flex align-center justify-center">
          <div className="text-center rounded-lg p-2 my-2 bg-purple-400 text-white text-[10px]">
            {moment(moment.unix(message?.timetoken / 10000000)).format(
              'DD MMMM YYYY',
            )}
          </div>
        </div>
      )}
      <div className="my-2" id={`message-${message?.timetoken}`}>
        <div className="w-full px-2 flex flex-row">
          {isCurrentUserSender && <div className="flex-1" />}
          <div className="text-[10px] text-gray-600">
            {!isCurrentUserSender
              ? message?.meta?.senderName ?? 'Anonymous User'
              : ''}
          </div>
        </div>
        <div className="w-full flex flex-row items-center mt-[2px]">
          {isCurrentUserSender && <div className="flex-1" />}
          {isCurrentUserSender && (
            <div className="text-[8px] text-gray-400">
              {formatTimestamp(message?.timetoken, 'hh:mm A')}
            </div>
          )}
          <div
            className={`max-w-xl mx-2 p-2 rounded-t-2xl bg-white ${isCurrentUserSender ? `rounded-bl-2xl` : ` rounded-br-2xl`}`}
          >
            {message?.content?.files?.[0] &&
              renderFileContent(message?.content?.files?.[0])}
            {renderMessage()}
          </div>
          {!isCurrentUserSender && (
            <div className="text-[8px] text-gray-400">
              {formatTimestamp(message?.timetoken, 'hh:mm A')}
            </div>
          )}
        </div>
      </div>
      {(message?.content?.files ?? []).length > 1 &&
        (message?.content?.files ?? [])
          .filter((item, index) => index !== 0)
          .map((file) => (
            <div className="w-full flex flex-row items-center mt-[2px]">
              {isCurrentUserSender && <div className="flex-1" />}
              {isCurrentUserSender && (
                <div className="text-[8px] text-gray-400">
                  {formatTimestamp(message?.timetoken, 'hh:mm A')}
                </div>
              )}
              <div
                className={`max-w-xl mx-2 p-2 rounded-t-2xl bg-white ${isCurrentUserSender ? `rounded-bl-2xl` : ` rounded-br-2xl`}`}
              >
                {renderFileContent(file)}
              </div>
              {!isCurrentUserSender && (
                <div className="text-[8px] text-gray-400">
                  {formatTimestamp(message?.timetoken, 'hh:mm A')}
                </div>
              )}
            </div>
          ))}
    </>
  );
};

export default MessageBubble;
